import React from 'react';
import { imgUrl, loadData, dataObj, goToHomepage, detectMob, touchMobile, getPlayImg, imgUrl2 } from '../general/functions'
import MenuHdr from './Dashboard'



let isPause = false
let isScroll = false
let startX = 0
let saveClick = false
let faqData = null

const loadFaqs = async () => {

    await fetch(`${process.env.PUBLIC_URL}/faq.json`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    )
        .then((resfaq) => resfaq.json())
        .then((resfaq) => {

            faqData = resfaq

        }).catch(error => {
            faqData = null
        });


}

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.vidRef = React.createRef()

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',
            mainFullVid: 'full-vid/CHG_main_sound.mp4',

            redirect: '',
            marginLeft_1: '',
            marginLeft_2: '',
            marginLeft_3: '',
            vidData: null,
            vidWrapper: 'noHeight',
            machinesMenuWrapper: 'noHeight noMarginTop ',
            showNavBottom: '',
            pauseWrapperClass: '',
            playFullWrapperClass: '',


            navLeftText: '  ',
            navCenterText: ' Videos ',
            navRightText: '  ',
            centerNavClass: '',

            nextVid: 'nice',
            previousVid: 'zooz',
            showShadow: 'none',

            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
            currentVid: 'main',
            allVidText: '',
            timeToPause: 3,
            showNav: 'fullHeight',
            nextVidClass: "fullHeight",

            showLetMeSee: 'none',
            menuHomepage: 'fullHeight menuMarginBottom ',
            vidCounter: '',
            vidCounterClass: 'noDisplay',
            machinesVideoClass: '',
            machinesLineClass: 'noDisplay',
            pauseTitle: 'pauseIcon.png',
            machineNavTitle: '',
            vidDashTxtClass: '',
            vidMetadata: '',

            machineWrapper: '',
            faqHtml: [],
            activefaq: null,
            faqOpen: "noHeight",
            mobileFaqActive:""
        
        }
    }

    componentDidMount() {
        
        const prm = this.props.match.params.id
        loadFaqs()

        if (faqData !== null) {
            this.setFaqData(this.props.match.params.id)
        }
        else {
            this.validateFaqData()
        }

        loadData()

        this.setState({
            vidData: dataObj,
        })
        if (dataObj !== null) {
            this.setVideos()
        }
        else {
            this.handleSetVid()
        }

        if (prm === 'videos') {
            //

            setTimeout(
                function () {

                    this.showVideo('nice', true)
                }
                    .bind(this), 100
            );
        }
        else if (prm === 'vojo') {
            document.title = 'Vojo | Creative house group'
            setTimeout(
                function () {

                    this.showVideo('machines', true)
                }
                    .bind(this), 100
            );
        }


    }

    

    onWheelVideo = (event) => {

        if (!isScroll) {
            isScroll = true
            if (event.deltaY < 0) {
                //    scroll left
                this.doScroll(true)
            }
            else if (event.deltaY > 0) {
                this.doScroll(false)
            }
        }
    }

    doScroll = (_scrollLeft) => {
        if (_scrollLeft) {
            if (this.state.currentVid === "main") {

                this.showVideo(this.state.previousVid, false)
            }
            else if (this.state.currentVid.startsWith('machine')) {
                this.showVideo(this.state.previousVid, false)
            }
            else {
                this.showVideo(this.state.previousVid, false)
            }

        }
        else {
            if (this.state.currentVid.startsWith('machine')) {
                this.showVideo(this.state.nextVid, true)
            }
            else {
                this.showVideo(this.state.nextVid, true)
            }
        }

        setTimeout(
            function () {
                isScroll = false
            }
            , 250
        );

    }

    handleSetVid = () => {
        if (dataObj === null) {

            setTimeout(
                function () {
                    this.handleSetVid()
                }
                    .bind(this), 100
            );
        }
        else {

            this.setVideos()

        }

    }

    setVideos = () => {

        var keyCount = Object.keys(dataObj.video).length;

        let arrVid = []
        for (var i = 0; i < keyCount; i++) {
            const id = dataObj.video[i].id

            let vidSrc = imgUrl() + "vid/" + dataObj.video[i].bgVideoUrl
            let poster = ""

            if (detectMob()) {

                //vidSrc = ""
                vidSrc = imgUrl() + "vid/bg-vid/blank.mp4"
                poster = imgUrl() + "" + dataObj.video[i].bgImg

            }

            arrVid.push(

                <div className='vidContainerRight' key={'wrapper_' + id} id={'wrapper_' + id}
                    onClick={this.playVidFromVid}
                >
                    <div className='relative' >
                        <video id={'vid_' + id} key={'vid_' + id} muted playsInline loop width='100%' preload='auto'
                            poster={poster} >
                            <source src={vidSrc} type="video/mp4" />
                        </video>
                    </div>
                </div>


            )
        }
        this.setState({
            allVidText: arrVid
        })
    }

    onTimeUpdate = (_vidId) => {
        /*
        let vid = document.getElementById("vid_" + _vidId)
        if (vid.currentTime > this.state.timeToPause) {
            vid.pause()
        }
        */
    }

    pausePlay = (_pauseVid, _playVid) => {


        const blankSrc = "https://vojocloud.com/cloud/chg/2021/img/vid/bg-vid/blank.mp4"
        const vid1 = document.getElementById(_pauseVid)
        if (vid1.src !== "") {
            if (vid1.sr !== blankSrc) {
                vid1.pause()
                vid1.currentTime = 0
            }
        }


        if (_playVid.startsWith('vid_machi')) {
            const vid2 = document.getElementById(_playVid)

            vid2.play()

        }
        else if (!isPause) {

            const vid2 = document.getElementById(_playVid)
            const vid2Src = document.getElementById(_playVid).getElementsByTagName("source")[0].src



            if (vid2Src !== "") {
                if (vid2Src !== blankSrc) {
                    vid2.play()
                }
            }
        }


    }

    showMachines = (_vidId, _goUp) => {

        let showNavBottom = ''


        if (_vidId === "machine_home") {

            goToHomepage()
        }

        if (_vidId === 'machines') {


            var div = document.getElementById("wrapper_main")
            div.style.left = "100vw"


            var div2 = document.getElementById("wrapper_machine1")
            div2.style.left = "0"


            this.pausePlay('vid_main', 'vid_machine1')

            this.setState({
                currentVid: 'machine1',
                nextVid: 'machine1',
                menuHomepage: 'noHeight',
                showNav: '',
                machinesMenuWrapper: 'fullHeight  ',
                machinesVideoClass: '',
                machinesLineClass: '',
                centerNavClass: '',

                navLeftText: '  ',

                navRightText: ' ',
                previousVid: 'machine1',
                pauseWrapperClass: 'notMainVideo',
                playFullWrapperClass: '',
                vidDashTxtClass: ' vidDashTxtMarginLeft',
                vidMetadata: 'noDisplay',
                machineWrapper: ' machinesMobileBg',


            })
            this.setMachineData(1)

            setTimeout(
                function () {
                    this.setState({
                        vidDashTxtClass: '',
                        vidMetadata: '',

                    })
                }.bind(this), 150
            );

        }
        else {

            if (_goUp) {

                if (this.state.currentVid === 'machine1') {

                    this.setState({

                        showNav: 'fullHeight',

                        currentVid: 'main',
                        title: '',
                        text: '',
                        machinesVideoClass: '',
                        machinesLineClass: '',
                        machinesMenuWrapper: 'noHeight',
                        navLeftText: '  ',
                        navRightText: ' ',
                        navCenterText: ' Videos ',
                        centerNavClass: 'noWidth',
                        vidWrapper: 'noHeight',
                        menuHomepage: 'fullHeight menuMarginBottom ',
                        timeToPause: 3,
                        previousVid: 'zooz',

                        nextVid: 'nice',
                        vidCounterClass: 'noDisplay',
                        pauseWrapperClass: '',
                        playFullWrapperClass: '',
                        machineWrapper: '',
                    })

                    this.pausePlay('vid_machine1', 'vid_main')

                    const div1 = document.getElementById("wrapper_main")
                    div1.style.left = "0"

                    const div2 = document.getElementById("wrapper_machine1")
                    div2.style.left = "-100vw"
                    this.vidRef.current.src = imgUrl() + 'vid/' + this.state.mainFullVid


                }
                else {
                    let vid1_name = ""
                    let vid2_name = ""

                    if (this.state.currentVid === 'machine2') {
                        vid1_name = "machine2"
                        vid2_name = "machine1"

                        this.setMachineData(1)

                    }
                    else if (this.state.currentVid === 'machine3') {

                        vid1_name = "machine3"
                        vid2_name = "machine2"

                        this.setMachineData(2)
                    }
                    else if (this.state.currentVid === 'machine4') {

                        vid1_name = "machine4"
                        vid2_name = "machine3"




                        this.setMachineData(3)

                    }

                    this.setState({
                        currentVid: vid2_name,
                        nextVid: vid2_name,
                        previousVid: vid1_name,
                        pauseWrapperClass: 'notMainVideo',
                        playFullWrapperClass: '',
                        vidDashTxtClass: ' vidDashTxtMargin',
                        vidMetadata: 'noDisplay',
                        machineWrapper: ' machinesMobileBg'

                    })



                    this.pausePlay('vid_' + vid2_name, 'vid_' + vid1_name)

                    const div = document.getElementById("wrapper_" + vid2_name)
                    div.style.left = "0"

                    const div2 = document.getElementById("wrapper_" + vid1_name)
                    div2.style.left = "-100vw"

                    this.restoreMetadata()

                }


            }
            else {

                let previousVid = ''
                let nextVid = ''
                let vid1_name = ""
                let vid2_name = ""

                if (_vidId === "machine1") {
                    vid1_name = "machine1"
                    vid2_name = "machine2"

                    this.setMachineData(2)
                    previousVid = vid2_name
                    nextVid = vid1_name
                }
                else if (_vidId === "machine2") {
                    vid1_name = "machine2"
                    vid2_name = "machine3"

                    this.setMachineData(3)
                    previousVid = vid2_name
                    nextVid = vid1_name
                }
                else if (_vidId === "machine3") {
                    vid1_name = "machine3"
                    vid2_name = "machine4"
                    nextVid = vid1_name


                    this.setMachineData(4)
                    previousVid = 'machine_home'
                }
                else {

                    this.showContact()
                }

                this.setState({
                    currentVid: vid2_name,
                    previousVid: previousVid,
                    nextVid: nextVid,
                    pauseWrapperClass: 'notMainVideo',
                    playFullWrapperClass: '',
                    vidDashTxtClass: ' vidDashTxtMarginLeft',
                    vidMetadata: 'noDisplay',
                    machineWrapper: ' machinesMobileBg'

                })

                this.pausePlay('vid_' + vid1_name, 'vid_' + vid2_name)
                const div1 = document.getElementById("wrapper_" + vid1_name)
                div1.style.left = "100vw"

                const div2 = document.getElementById("wrapper_" + vid2_name)
                div2.style.left = "0"

                this.restoreMetadata()
            }

            this.setState({
                showNavBottom: showNavBottom,
            })

        }


    }
    showContact = () => {
        document.location.href = `${process.env.PUBLIC_URL}/contact/`
    }
    restoreMetadata = () => {

        setTimeout(
            function () {
                this.setState({
                    vidDashTxtClass: '',
                    vidMetadata: '',

                })
            }.bind(this), 150
        );
    }
    showVideo = (_vidId, _goForward) => {

        //if (_vidId === 'machines') {
        if (_vidId.startsWith('machine')) {
            this.showMachines(_vidId, _goForward)

            return
        }


        let vidId = _vidId
        if (_vidId === "") {
            vidId = "main"
        }

        this.pausePlay('vid_' + this.state.currentVid, 'vid_' + vidId)

        const finalData = dataObj.video.find(x => x.id === vidId);


        if (vidId === "main") {
            this.setState({
                navLeftText: '  ',
                navRightText: ' ',
                navCenterText: ' Videos ',
                centerNavClass: 'noWidth',
                vidWrapper: 'noHeight',
                menuHomepage: 'fullHeight menuMarginBottom ',
                timeToPause: 3,
                previousVid: 'zooz',

                nextVid: 'nice',
                vidCounterClass: 'noDisplay',
                pauseWrapperClass: '',
                playFullWrapperClass: '',
            })
            this.vidRef.current.src = imgUrl2() + '' + this.state.mainFullVid
        }
        else {
            this.vidRef.current.src = imgUrl2() + '' + finalData.videoUrl
            this.setState({
                navLeftText: '',
                navRightText: '',
                navCenterText: '',

                vidWrapper: 'fullHeight',
                menuHomepage: 'noHeight',
                vidCounterClass: 'fullHeight',
                pauseWrapperClass: 'notMainVideo',
                playFullWrapperClass: 'showMe',

                vidUrl: imgUrl2() + '' + finalData.videoUrl,

                bgImg: imgUrl() + finalData.bgImg,
                title: finalData.title,
                text: finalData.text,
                newText: finalData.text,
                vidGroup: finalData.groupTitle,
                vidLength: finalData.length,
                vidType: finalData.type,
                vidUsage: finalData.usage,

                previousVid: finalData.previousVid,
                nextVid: finalData.nextVid,
                timeToPause: parseFloat(finalData.timeToPause),
                vidCounter: finalData.index,
                vidMetadata: 'noDisplay',
            })

            if (_goForward) {
                this.setState({
                    vidDashTxtClass: 'vidDashTxtMargin ',
                    centerNavClass: 'groupPadding',
                })
            }
            else {
                this.setState({
                    vidDashTxtClass: 'vidDashTxtMarginLeft ',
                    centerNavClass: 'groupPaddingLeft',
                    //centerNavClass: 'fullWidth vidDashTxtMarginLeft',
                })
            }

            let nextVidClass = "fullHeight"

            if (finalData.nextVid === "") {

                nextVidClass = "lessOpacity"
            }

            this.setState({
                nextVidClass: nextVidClass,

            });

            //vidDashTxtMargin

            setTimeout(
                function () {
                    this.setState({
                        vidDashTxtClass: '',
                        text: this.state.newText,
                        vidMetadata: '',


                    })
                }.bind(this), 150
            );

        }

        this.setState({

            //     currentVid: _vidId,

        },
            () => {
                setTimeout(
                    function () {
                        if (vidId === "main") {
                            this.setState({
                                centerNavClass: '',
                            })
                        }
                        else {
                            this.setState({
                                navCenterText: finalData.groupTitle,
                                centerNavClass: 'fullWidth  centerNavTran ',
                            })
                        }
                    }.bind(this), 150
                );


                if (_goForward) {

                    if (_vidId === "") {
                        this.showHome()
                        /*
                        const vid = document.getElementById("wrapper_main")
                        vid.style.left = "0"
 
                        var all = document.getElementsByClassName('vidContainerRight');
                        for (var i = 0; i < all.length; i++) {
                            all[i].style.left = '100vw';
 
                            // var vid2 = document.getElementById("wrapper_" + vidId)
                            // vid2.style.left = "0"
                        }
                        */

                    }
                    else {
                        const vid = document.getElementById("wrapper_" + this.state.currentVid)
                        vid.style.left = "-100vw"

                        const vid2 = document.getElementById("wrapper_" + vidId)
                        vid2.style.left = "0"

                    }




                }
                else {

                    const vid = document.getElementById("wrapper_" + vidId)
                    if (vidId === "main") {
                        vid.style.left = "0"
                    }
                    else {
                        vid.style.left = "0"
                    }

                    const vid2 = document.getElementById("wrapper_" + this.state.currentVid)
                    vid2.style.left = "100vw"
                }


                this.setState({
                    currentVid: vidId,

                })
            }

        )
    }

    validateFaqData = () => {
        if (faqData === null) {
            setTimeout(
                function () {
                    this.validateFaqData()
                }
                    .bind(this), 100
            );
        }
        else {

            this.setFaqData(this.props.match.params.id)

        }

    }

    setFaqData = (selitem) => {


        let i = 0
        let faqstr = []
        let faqitem = null

        for (i; i < faqData.faq.length; i++) {
           
            /*faqstr.push(<div>faqData.faq[i].question</div>)*/
            faqitem = faqData.faq[i]
            console.log("item", faqitem.question)
            let itemid = "faq_item_" + faqitem.id
            faqstr.push(<div className="faq-item" onClick={() => this.clickFaq(itemid)} id={itemid}><div className="faq-item-title"><div>{faqitem.question}</div><div className='faq-toggler'>{(selitem == itemid) ? '-' : '+'}</div></div>{(selitem == itemid) && <div className="faq-item-answ" dangerouslySetInnerHTML={{__html:faqitem.answer}}></div>}</div>)
        }

        this.setState({

            faqHtml: faqstr

        })



        

    }

    showAbout = () => {
        document.location.href = `${process.env.PUBLIC_URL}/about/`
    }
    showHome = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }

    playVidFromVid = () => {
        if (!detectMob()) {
            this.playVid()
        }
    }

    playVid = () => {

        this.vidRef.current.play();
        this.setState({
            showShadow: 'inline',
            menuMaxWidth: '0vw',
            menuPaddingRight: '2vw',
        })

        if (!this.isPauseOrMobile()) {
            const vid = document.getElementById("vid_" + this.state.currentVid)
            vid.pause()

        }


    }

    hideVid = () => {
        this.vidRef.current.pause();
        this.vidRef.current.currentTime = 0;
        this.setState({
            showShadow: 'none',
            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
        })


        if (!this.isPauseOrMobile()) {
            const vid = document.getElementById("vid_" + this.state.currentVid)
            vid.play()
            isPause = false


        }

    }

    isPauseOrMobile = () => {
        if (detectMob()) {
            return true
        }
        else if (isPause) {
            return true
        }
        else {
            return false
        }

    }

    pauseCurrentVid = () => {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        if (vid.paused) {
            vid.play()
            isPause = false

            this.setState({
                pauseTitle: 'pauseIcon.png'
            })
        }
        else {
            vid.pause()
            isPause = true
            this.setState({
                pauseTitle: getPlayImg()
            })
        }

    }

    moveUp = () => {
        this.showMachines(this.state.currentVid, true)
    }

    moveDown = () => {
        this.showMachines(this.state.currentVid, false)
    }

    about = () => {

    }

    setMachineData = (_machineId) => {
        let showLetMeSee = 'none'
        let machineTextColor = ""
        let machineText = ""
        let machineTitle = ""
        let machineUrl = ""
        let machineNavTitle = ""
        let navLeftText = ''
        let navCenterText = ' | '
        let navRightText = ''

        if (_machineId === 1) {
            machineTitle = "Interactive video marketing machines "
            machineText = "We can build your Vojo to do whatever you need:  \n  Generate or qualify new leads, demonstrate your product in context \n Customize your videos by yourself and more"
            machineTextColor = "#931271"
            machineUrl = ""
            showLetMeSee = "none"
            machineNavTitle = "Vojo"
            navRightText = "Home"
            navLeftText = "Promote"

        }
        else if (_machineId === 2) {
            machineTitle = "Promote"
            machineText = 'With Vojo Promote your team can change variables within the video by themselves. They can update the details of the next virtual events, present your product with the client\'s branding or add a link to your sales persons contacts within the follow-up video'
            machineTextColor = "#2f52ff"
            machineUrl = "https://vojo.io/promote/online-event-platform/#/"
            showLetMeSee = ""
            machineNavTitle = "Vojo promote"
            navRightText = "Vojo"
            navLeftText = "Engage"
        }
        else if (_machineId === 3) {
            machineTitle = "Engage"
            machineText = 'Vojo-Engage can be your self service demo which interacts with the viewer in their context. Enrich the video with embedded forms & menus and interactive questions to qualify leads or just increase engagement'
            machineTextColor = "#b71e87"
            machineUrl = "https://vojo.io/demo/"
            showLetMeSee = ""
            machineNavTitle = "Vojo engage"
            navRightText = "Promote"
            navLeftText = "Convert"
        }
        else if (_machineId === 4) {
            machineTitle = "Convert"
            machineText = 'Vojo-Convert helps viewers understand your product features and options. They can make their choices within the video itself and get a preference  summary at the end to assist them in making their buying journey decisions.'
            machineTextColor = "#2f52ff"
            machineUrl = "https://vojo.io/convert/"
            showLetMeSee = ""
            machineNavTitle = "Vojo convert"
            navRightText = "Engage"
            navLeftText = "Contact"
        }

        this.setState({
            title: machineTitle,
            text: machineText,
            machineTextColor: machineTextColor,
            showLetMeSee: showLetMeSee,
            machineUrl: machineUrl,
            machineNavTitle: machineNavTitle,
            //navCenterText: machineNavTitle,
            navLeftText: navLeftText,
            navCenterText: navCenterText,
            navRightText: navRightText
        })
    }

    touchStart = (e) => {
        startX = e.touches[0].clientX
    }

    touchEnd = (e) => {
        if (touchMobile()) {
            if (!isScroll) {
                isScroll = true

                const endX = e.changedTouches[0].clientX
                const diff = Math.abs(endX - startX)
                if (diff > 35) {
                    if (endX > startX) {
                        this.doScroll(true)
                    }
                    else {
                        this.doScroll(false)
                    }
                }
                setTimeout(
                    function () { isScroll = false }, 250
                );
            }
        }
    }

    clickShadow = () => {


        if (saveClick) {
            saveClick = false
        }
        else {
            this.hideVid()
        }

    }

    clickVideo = (event) => {
        saveClick = true
        event.preventDefault();
    }

    clickFaq = (item) => {
        
        this.setFaqData(item == this.state.activefaq ? null : item)
        this.setState({ activefaq: item == this.state.activefaq ? null : item })
    }

    showFaq = () => {
        if (detectMob()) {
            this.pauseCurrentVid()
        }
        
        this.setState({faqOpen:"", menuHomepage:"noHeight", mobileFaqActive:"activeFaq"})
    }

    closeFaq = () => {
        if (detectMob()) {
             var cvid = document.getElementById("vid_" + this.state.currentVid)
            if (cvid.paused) {
                cvid.play()
            }
        }
        this.setState({faqOpen:"noHeight", menuHomepage:"fullHeight menuMarginBottom", mobileFaqActive:""})
    }


    render() {
        return (

            <div className='background' onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
                <div className='vidContainer' id='vidContainer' onWheel={this.onWheelVideo}>
                    <div className='vidContainerOn' id='wrapper_main'
                        onClick={this.playVidFromVid} >
                        <div className='relative' >
                            <video playsInline autoPlay muted loop id='vid_main' preload='auto'>
                                <source src={imgUrl() + "vid/chg-1080-3.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    {
                        this.state.allVidText
                    }
                    <div className='wrapper_machines' id='wrapper_machines'>
                        <div className='wrapper_machine1' id='wrapper_machine1' >
                            <img src={imgUrl() + 'vojo_logo.png'} alt='Vojo logo' />
                            <video playsInline muted loop id='vid_machine1' >
                                <source src={'https://vojocloud.com/cloud/chg-vojo/v2/part1.mp4'} type="video/mp4" />
                            </video>
                        </div>
                        <div className='wrapper_machine2' id='wrapper_machine2'  >
                            <img src={imgUrl() + 'vojo_logo.png'} alt='Vojo logo' />
                            <video playsInline muted loop id='vid_machine2' >
                                <source src={'https://vojocloud.com/cloud/chg-vojo/mobile/Sh02%20Mobile%20V05.mp4'} type="video/mp4" />
                            </video>
                        </div>
                        <div className='wrapper_machine3' id='wrapper_machine3'  >
                            <img src={imgUrl() + 'vojo_logo.png'} alt='Vojo logo' />
                            <video playsInline muted loop id='vid_machine3' >
                                <source src={'https://vojocloud.com/cloud/chg-vojo/mobile/Sh03%20Mobile%20V04.mp4'} type="video/mp4" />
                            </video>
                        </div>
                        <div className='wrapper_machine4' id='wrapper_machine4'  >
                            <img src={imgUrl() + 'vojo_logo.png'} alt='Vojo logo' />
                            <video playsInline muted loop id='vid_machine4' >
                                <source src={'https://vojocloud.com/cloud/chg-vojo/mobile/Sh04%20Mobile%20V02.mp4'} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='menuWrapper' style={{
                    maxWidth: this.state.menuMaxWidth,
                    paddingRight: this.state.menuPaddingRight,
                }} >
                    <MenuHdr >
                        <div
                            style={{
                                border: '1px solid transparent',
                                marginLeft: '-1px',
                            }}
                        >
                            <div className={'navWrapperMobile ' + this.state.machineWrapper + '' + this.state.mobileFaqActive}  >
                                <div className={'mainTitle ' + this.state.menuHomepage} >

                                    <br />
                                    B2B MARKETING <br />
                                    BY <span>VIDEO </span> <br className='brTitle' />
                                    <br />
                                    <div className='faqTitle faqBtn' onClick={this.showFaq}>FAQ</div>

                                </div>
                                <div className={'faqWrapper ' + this.state.faqOpen}>
                                    <div className='faqTitle'>FAQ<span className="faqClose" onClick={this.closeFaq}>X</span></div>
                                    <div className='accordion'>
                                       {this.state.faqHtml}
                                    </div>
                                </div>


                                <div className={'vidDashWrapper ' + this.state.vidWrapper} >
                                    <div className={'noMargin ' + this.state.vidDashTxtClass} >
                                        <div className={this.state.vidMetadata} >
                                            <div className='vidDashHdr' >{this.state.title}</div>
                                            <div className={'vidDashTxt '} >{this.state.text}</div>

                                            <div className='vidDetails' >
                                                <span>Type: </span>{this.state.vidType}  <br />
                                                <span>Length: </span>{this.state.vidLength}  <br />
                                                <span>Usage: </span>{this.state.vidUsage}  <br />

                                            </div>
                                        </div>
                                    </div>
                                    <div className={'vidPlayWrapper '} >
                                        <div onClick={this.playVid} >
                                            <img src={imgUrl() + 'icons/playIcon-v3.png'} alt='Pause background' />
                                        </div>
                                    </div>
                                </div>

                                <div className={'machinesMenuWrapper   ' + this.state.machinesMenuWrapper} >
                                    <div className={'machinesData ' + this.state.vidDashTxtClass} >
                                        <div className={this.state.vidMetadata} >
                                            <div className='machinesHdr' >{this.state.title}</div>
                                            <div className='machinesTxt' >{this.state.text}</div>
                                            <a target='_blank' href={this.state.machineUrl} rel="noreferrer">
                                                <div className='letMeSee' style={{
                                                    color: this.state.machineTextColor,
                                                    display: this.state.showLetMeSee,
                                                }} >Let me see</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className={'navWrapper ' + this.state.showNav}>
                                    <div className='leftNav'  >
                                        <table onClick={() => this.showVideo(this.state.previousVid, false)} cellSpacing='0' >
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='navArraow' ><img src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                    </td>
                                                    <td>
                                                        {this.state.navLeftText}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={'centerNav ' + this.state.centerNavClass} > {this.state.navCenterText} </div>
                                    <div className='rightNav'   >
                                        <table onClick={() => this.showVideo(this.state.nextVid, true)}>
                                            <tbody>
                                                <tr valign='middle'>
                                                    <td>
                                                        {this.state.navRightText}
                                                    </td>
                                                    <td>
                                                        <div className={'navArraow ' + this.state.nextVidClass} style={{
                                                            overflow: 'hidden',


                                                        }} ><img className='rotate180' src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <div className={'vidCounter ' + this.state.vidCounterClass} >
                                    <span>{this.state.vidCounter} </span> / 20

                                </div>
                            </div>



                        </div>
                        <div className={'playFullMobile ' + this.state.playFullWrapperClass} onClick={this.playVid} >
                            <div>
                                <img src={imgUrl() + 'icons/playIcon-v3.png'} alt='play' />
                            </div>
                        </div>



                    </MenuHdr>


                </div>
                {this.state.redirect}


                <div className='shadow' style={{ display: this.state.showShadow, }} onClick={this.clickShadow}>
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                        <img src={imgUrl() + 'hide.png'} alt='Hide Video' style={{}} onClick={() => this.hideVid()} />

                        <video ref={this.vidRef} controls onClick={this.clickVideo} >
                            <source src={imgUrl2() + 'full-vid/CHG_main_sound.mp4'} type="video/mp4" >
                            </source>
                        </video>
                    </div>
                </div>

                <div className={'pauseVid ' + this.state.pauseWrapperClass} onClick={this.pauseCurrentVid}>
                    <div>
                        <img src={imgUrl() + 'icons/' + this.state.pauseTitle} alt='Pause background' />
                    </div>
                </div>


            </div >
        )
    }
}
export default Main;
