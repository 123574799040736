export let dataObj = null;
export let faqObj = null;

export function PlayTitleSign() {
    return <div> &nbsp;  &#9654; </div>;
}

export const imgUrl = () => {
    return "https://vojocloud.com/cloud/chg/2021/img/"
}


export const imgUrl2 = () => {
    return "https://vojocloud2.com/cloud/chg/site/"
}


export const loadData = async () => {


    await fetch(`${process.env.PUBLIC_URL}/data.json`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    )
        .then((res) => res.json())
        .then((res) => {
            //console.log(res)
            dataObj = res


        }).catch(error => {
            dataObj = null
            // console.log(error)
        });
}

export const loadFaq = async () => {


    await fetch(`${process.env.PUBLIC_URL}/faq.json`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    )
        .then((res) => res.json())
        .then((res) => {
            //console.log(res)
            faqObj = res


        }).catch(error => {
            faqObj = null
            // console.log(error)
        });
}

export const goToHomepage = () => {
    document.location.href = `${process.env.PUBLIC_URL}/`
}

export const detectMob = () => {
    //   console.log(window.innerHeight + " " + window.innerWidth)
    const h = window.innerHeight
    let w = window.innerWidth
    if (h < w) {
        w = h
    }
    if (w < 500) {
        return true
    }
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const touchMobile = () => {
    var isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i)
    if (isSamsungBrowser) {
        return true
    }
    else {
        return true
    }

}

export const getPlayImg = () => {
    return 'playIcon-v5.png'
}