import React, { useEffect, useState } from 'react';

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


function CookiesMsg(props) {
    //cookieMsgClass = 'cookiesMsg'
    const [cookieClass, setCookieClass] = useState(0);
    // 
    useEffect(() => {
        const val = getCookie("showCookieMsg")
        // alert(val)
        if (val !== "hide") {
            setCookieClass('cookiesMsg')
        }
        else {
            setCookieClass('noDisplay')
        }

    }, []);

    function hideCookie(event) {
        event.preventDefault()

        localStorage.setItem("showCookieMsg", "okay");

        setCookieClass('noDisplay')
        setCookie("showCookieMsg", "hide", 5);
    }

    return (
        <div className={cookieClass}>
            <div className={'cookiesMsgHide'} >
                <span onClick={hideCookie}> &#x2715;</span><br />
            </div>
            <div className='cookiesMsgText'>

                We use cookies to ensure you get the best user experience. If you continue without changing your settings, we will  assume that you have read and accepted
                <a href='https://creative-house-group.com/cookies-policy/' target='_blank' rel="noreferrer" >Creative House Group Cookie Policy</a>
                <button onClick={hideCookie}>I Accept</button>
            </div>
        </div>
    );
}

export default CookiesMsg