import React from 'react';
import { imgUrl, dataObj, detectMob, getPlayImg } from '../general/functions'
import MenuHdr from './Dashboard'


let isScroll = false
let startX = 0

class WorkFromHere extends React.Component {
    constructor(props) {
        super(props);

        this.vidRef = React.createRef()

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',

            redirect: '',


            vidWrapper: 'noHeight',
            machinesMenuWrapper: 'noHeight noMarginTop ',
            showNavBottom: '',


            navLeftText: ' About ',
            navCenterText: ' | ',
            navRightText: ' Contact ',
            centerNavClass: '',



            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
            currentVid: 'main',
            showNav: 'fullHeight',

            menuHomepage: 'fullHeight menuMarginBottom ',
            pauseClass: '',

            pauseTitle: 'pauseIcon.png',

        }
    }

    componentDidMount() {
        document.title = 'Join our team | Creative house group'


        if (detectMob()) {
            const vid = document.getElementById('vid_main')
            vid.pause()

            this.setState({
                pauseClass: 'noDisplay'
            })

        }

    }


    onWheelVideo = (event) => {
        if (!isScroll) {
            isScroll = true
            if (event.deltaY < 0) {

                this.doScroll(true)
            }
            else if (event.deltaY > 0) {
                this.doScroll(false)
            }
        }
    }

    touchStart = (e) => {
        startX = e.touches[0].clientX
    }

    touchEnd = (e) => {
        const endX = e.changedTouches[0].clientX
        const diff = Math.abs(endX - startX)
        if (diff > 35) {
            if (endX > startX) {
                this.doScroll(true)
            }
            else {
                this.doScroll(false)
            }
        }
    }

    doScroll = (_scrollLeft) => {
        if (_scrollLeft) {
            this.showAbout()
        }
        else {
            this.showContact()
        }
    }

    pauseCurrentVid = () => {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        if (vid.paused) {
            vid.play()

            this.setState({
                pauseTitle: 'pauseIcon.png',
            })
        }
        else {
            vid.pause()

            this.setState({
                pauseTitle: getPlayImg(),

            })
        }

    }


    showHome = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }

    showContact = () => {
        document.location.href = `${process.env.PUBLIC_URL}/contact/`
    }

    showAbout = () => {
        document.location.href = `${process.env.PUBLIC_URL}/about/`
    }

    render() {
        return (

            <div className='background' >
                <div className='vidContainer' id='vidContainer' onWheel={this.onWheelVideo} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
                    <div className='vidContainerOn' id='wrapper_main' style={{

                    }}>
                        <div className='relative' >
                            <video playsInline autoPlay muted loop id='vid_main' preload='auto'>
                                <source src={imgUrl() + "vid/bg-vid/woman-working-720.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='menuWrapper' style={{

                }} >
                    <MenuHdr >
                        <div >
                            <div className='aboutBg' onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
                                <div className={'aboutTitle ' + this.state.menuHomepage} >


                                    <div className='aboutText' >
                                        <span>Join our team</span><br />
                                        We are a cloud based company. <br />
                                        We believe that talented people should have  <br />
                                        the freedom to work from wherever they are. <br />
                                        Using advanced collaboration and online tools  <br />
                                        enables us to work with digital nomads from  <br />
                                        around the world. <br /> <br />

                                        Join our team of 2D/3D animators ,motion  <br />
                                        graphics or UI/UX designers. Send us your  <br />
                                        work examples and help us promote digital  <br />
                                        freedom lifestyle <br /> <br />

                                        Creative House Group
                                    </div>
                                </div>


                                <div className={'navWrapper ' + this.state.showNav}>
                                    <div className='leftNav'  >
                                        <table onClick={() => this.showAbout()} >
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='navArraow' ><img src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                    </td>
                                                    <td>
                                                        {this.state.navLeftText}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={'centerNav ' + this.state.centerNavClass} > {this.state.navCenterText} </div>
                                    <div className='rightNav'   >
                                        <table onClick={() => this.showContact()}>
                                            <tbody>
                                                <tr valign='middle'>
                                                    <td>
                                                        {this.state.navRightText}
                                                    </td>
                                                    <td>
                                                        <div className={'navArraow ' + this.state.nextVidClass} style={{
                                                            overflow: 'hidden',


                                                        }} ><img className='rotate180' src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>



                            </div>
                        </div>




                    </MenuHdr>

                </div>
                {this.state.redirect}
                <div className={'pauseVid ' + this.state.pauseClass} onClick={this.pauseCurrentVid}>
                    <div>
                        <img src={imgUrl() + 'icons/' + this.state.pauseTitle} alt='Pause background' />
                    </div>
                </div>

            </div >
        )
    }
}
export default WorkFromHere;
