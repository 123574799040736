import React from 'react';
import { imgUrl, dataObj, detectMob, getPlayImg } from '../general/functions'
import MenuHdr from './Dashboard'


//let isPause = false
let isScroll = false
let startX = 0

class About extends React.Component {
    constructor(props) {
        super(props);

        //        this.vidRef = React.createRef()

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',

            redirect: '',


            vidWrapper: 'noHeight',
            machinesMenuWrapper: 'noHeight noMarginTop ',
            showNavBottom: '',


            navLeftText: ' Home ',
            navCenterText: ' | ',
            navRightText: ' Join us ',
            centerNavClass: '',



            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
            currentVid: 'main',
            showNav: 'fullHeight',

            menuHomepage: 'fullHeight menuMarginBottom ',
            pauseClass: '',

            pauseTitle: 'pauseIcon.png',

        }
    }

    componentDidMount() {


        document.title = 'About | Creative house group'

        if (detectMob()) {
            const vid = document.getElementById('vid_main')
            vid.pause()

            this.setState({
                pauseClass: 'noDisplay'
            })

        }

    }

    onWheelVideo = (event) => {
        if (!isScroll) {
            isScroll = true
            if (event.deltaY < 0) {

                this.doScroll(true)
            }
            else if (event.deltaY > 0) {
                this.doScroll(false)
            }
        }
    }

    touchStart = (e) => {
        startX = e.touches[0].clientX
    }

    touchEnd = (e) => {
        const endX = e.changedTouches[0].clientX
        const diff = Math.abs(endX - startX)
        if (diff > 35) {
            if (endX > startX) {
                this.doScroll(true)
            }
            else {
                this.doScroll(false)
            }
        }
    }


    doScroll = (_scrollLeft) => {
        if (_scrollLeft) {
            this.showHome()

        }
        else {
            this.showJoin()
        }
    }

    pauseCurrentVid = () => {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        if (vid.paused) {
            vid.play()


            this.setState({
                pauseTitle: 'pauseIcon.png',
            })
        }
        else {
            vid.pause()

            this.setState({
                pauseTitle: getPlayImg(),

            })
        }


    }




    showHome = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }

    showContact = () => {
        document.location.href = `${process.env.PUBLIC_URL}/contact/`
    }


    showJoin = () => {
        document.location.href = `${process.env.PUBLIC_URL}/join/`
    }


    render() {
        return (

            <div className='background'  >
                <div className='vidContainer' id='vidContainer' onWheel={this.onWheelVideo} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}>
                    <div className='vidContainerOn' id='wrapper_main' style={{

                    }}>
                        <div className='relative' >
                            <video playsInline autoPlay muted loop id='vid_main' preload='auto' style={{

                            }}>
                                <source src={imgUrl() + "vid/chg-1080-2.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='menuWrapper'>

                    <MenuHdr >
                        <div className='aboutBg' onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}  >
                            <div className={'aboutTitle ' + this.state.menuHomepage} >


                                <div className='aboutText' >
                                    <span>About</span><br />
                                    We create online marketing videos & machines. <br /><br />
                                    Creative House Group is a trusted video creator  <br />
                                    by leading brands worldwide. Some of our clients  <br />
                                    are on the top of their category and some are on  <br />
                                    their way. How do we know? Because we already  <br />
                                    made the journey from medium company to  <br />
                                    unicorn more than 6 times with our clients.  <br /><br />
                                    We know how to tell a story, no matter how  <br />
                                    complicated your technology. We care about  <br />
                                    your results and proud to be a part of your  <br />
                                    success <br /> <br />

                                    We know what investors and the market are  <br />
                                    looking for, what is going on in your industry,  <br />
                                    how to differentiate your brand and how to  <br />
                                    create video and interactive tools to impact  <br />
                                    business growth. <br /> <br />
                                    We might know a lot, but it’s because we listen. <br />

                                    <a href='../contact' >Talk to us</a>
                                </div>
                            </div>


                            <div className={'navWrapper ' + this.state.showNav}>
                                <div className='leftNav'  >
                                    <table onClick={() => this.showHome()} >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='navArraow' ><img src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                </td>
                                                <td>
                                                    {this.state.navLeftText}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'centerNav ' + this.state.centerNavClass} > {this.state.navCenterText} </div>
                                <div className='rightNav'   >
                                    <table onClick={() => this.showJoin()}>
                                        <tbody>
                                            <tr valign='middle'>
                                                <td>
                                                    {this.state.navRightText}
                                                </td>
                                                <td>
                                                    <div className={'navArraow ' + this.state.nextVidClass} style={{
                                                        overflow: 'hidden',


                                                    }} ><img className='rotate180' src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>



                        </div>

                        <div className={'pauseVid ' + this.state.pauseClass} onClick={this.pauseCurrentVid}>

                            <div>
                                <img src={imgUrl() + 'icons/' + this.state.pauseTitle} alt='Pause background' />
                            </div>


                        </div>




                    </MenuHdr>

                </div>
                {this.state.redirect}


            </div >
        )
    }
}
export default About;