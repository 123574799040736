import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './css/style.css';

import Main from './components/Main'
import About from './components/About'
import Contact from './components/Contact'
import WorkFromHere from './components/WorkFromHere'
import News from './components/News'
import Campaign from './components/Campaign';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }




  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path='/join' component={WorkFromHere} />
          <Route path='/news/:id' component={News} />
          <Route path='/news/' component={News} />

          <Route path='/campaign/:id' component={Campaign} />
          <Route path='/:id' component={Main} />

          <Route path="/new-chg-website/" exact component={Main} />
          <Route path="/new-chg-website/about" exact component={About} />
          <Route path="/new-chg-website/contact" exact component={Contact} />
          <Route path='/new-chg-website/join' component={WorkFromHere} />
          <Route path='/new-chg-website/news' component={News} />
          <Route path='/new-chg-website/campaign/:id' component={Campaign} />
          <Route path='/new-chg-website/:id' component={Main} />


        </Switch>
      </Router >
    )
  }
}
export default App;
