import React from 'react';
import { imgUrl, dataObj, detectMob, getPlayImg } from '../general/functions'
import MenuHdr from './Dashboard'


let isScroll = false
let startX = 0

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.vidRef = React.createRef()
        this.thankYou = this.thankYou.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',

            redirect: '',


            vidWrapper: 'noHeight',
            machinesMenuWrapper: 'noHeight noMarginTop ',
            showNavBottom: '',


            navLeftText: ' Join us ',
            navCenterText: ' | ',
            navRightText: '  News ',
            centerNavClass: '',



            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
            currentVid: 'main',
            showNav: 'fullHeight',

            menuHomepage: 'fullHeight menuMarginBottom ',
            pauseClass: '',
            contactTxtClass: '',
            thankYouClass: 'noHeight',

            pauseTitle: 'pauseIcon.png',

        }
    }

    componentDidMount() {
        //loadData()
        // console.log(dataObj)

        document.title = 'Contact us | Creative house group'

        if (detectMob()) {
            const vid = document.getElementById('vid_main')
            vid.pause()

            this.setState({
                pauseClass: 'noDisplay'
            })

        }


    }

    onWheelVideo = (event) => {
        if (!isScroll) {
            isScroll = true
            if (event.deltaY < 0) {

                this.doScroll(true)
            }
            else if (event.deltaY > 0) {
                this.doScroll(false)
            }
        }
    }

    touchStart = (e) => {
        startX = e.touches[0].clientX
    }

    touchEnd = (e) => {
        const endX = e.changedTouches[0].clientX
        const diff = Math.abs(endX - startX)
        if (diff > 35) {
            if (endX > startX) {
                this.doScroll(true)
            }
            else {
                this.doScroll(false)
            }
        }
    }


    doScroll = (_scrollLeft) => {
        if (_scrollLeft) {
            this.showJoin()
        }
        else {
            this.showNews()
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);


        fetch(
            "https://creative-house-group.com/getForm.php?" +
            Math.floor(Math.random() * 999999),
            { method: "POST", body: data, headers: {} }
        )
            .then((res) => {
                res.json()
                this.thankYou();
            })
            ;
    }

    thankYou = () => {
        this.setState({
            contactTxtClass: 'noHeightImportant'
        })
        setTimeout(
            function () {
                this.setState({
                    thankYouClass: 'fullHeight'
                })
            }.bind(this), 250
        );

    };


    pauseCurrentVid = () => {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        if (vid.paused) {
            vid.play()


            this.setState({
                pauseTitle: 'pauseIcon.png',
            })
        }
        else {
            vid.pause()

            this.setState({
                pauseTitle: getPlayImg(),

            })
        }

    }


    showHome = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }


    showAbout = () => {
        document.location.href = `${process.env.PUBLIC_URL}/about/`
    }


    showJoin = () => {
        document.location.href = `${process.env.PUBLIC_URL}/join/`
    }

    showNews = () => {
        document.location.href = `${process.env.PUBLIC_URL}/news/`
    }

    render() {
        return (

            <div className='background' >
                <div className='vidContainer' id='vidContainer' onWheel={this.onWheelVideo} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}  >
                    <div className='vidContainerOn' id='wrapper_main' style={{

                    }}>
                        <div className='relative' >
                            <video playsInline autoPlay muted loop id='vid_main' preload='auto'>
                                <source src={imgUrl() + "vid/chg-1080-2.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='menuWrapper' style={{


                }} >
                    <MenuHdr >
                        <div className='aboutBg' onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}  >
                            <div className={'contactTitle ' + this.state.menuHomepage} >


                                <div className={'contactText ' + this.state.contactTxtClass} >

                                    <div className='joinUsForm'>
                                        <span>Contact</span><br />
                                        <form onSubmit={this.handleSubmit} id="create-course-form" ref={this.formRef} >
                                            <input
                                                id="businessEmail"
                                                name="businessEmail"
                                                type="email"
                                                required
                                                placeholder="Business Email *"
                                            />

                                            <br></br>

                                            <input
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                required
                                                placeholder="Full Name *"
                                            />

                                            <br></br>

                                            <input
                                                id="company"
                                                name="company"
                                                type="text"

                                                placeholder="Company"
                                            />

                                            <br></br>

                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"

                                                placeholder="Phone"
                                            />

                                            <br></br>

                                            <textarea
                                                id="message"
                                                name="message"
                                                rows='3'


                                                placeholder="Message"
                                            />


                                            <button>SEND</button>
                                        </form>
                                    </div>
                                </div>
                                <div className={'thankYou ' + this.state.thankYouClass} >
                                    <br />
                                    <br />
                                    Thank you
                                </div>
                            </div>


                            <div className={'navWrapper ' + this.state.showNav}>
                                <div className='leftNav'  >
                                    <table onClick={() => this.showJoin()} >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='navArraow' ><img src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                </td>
                                                <td>
                                                    {this.state.navLeftText}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'centerNav ' + this.state.centerNavClass} > {this.state.navCenterText} </div>
                                <div className='rightNav'   >
                                    <table onClick={() => this.showNews()}>
                                        <tbody>
                                            <tr valign='middle'>
                                                <td>
                                                    {this.state.navRightText}
                                                </td>
                                                <td>
                                                    <div className={'navArraow ' + this.state.nextVidClass} style={{
                                                        overflow: 'hidden',


                                                    }} ><img className='rotate180' src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>



                        </div>




                    </MenuHdr>

                </div>
                {this.state.redirect}
                <div className={'pauseVid ' + this.state.pauseClass} onClick={this.pauseCurrentVid}>

                    <div>
                        <img src={imgUrl() + 'icons/' + this.state.pauseTitle} alt='Pause background' />
                    </div>

                </div>

            </div >
        )
    }
}
export default Contact;
