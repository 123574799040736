import React from 'react';
import { imgUrl } from '../general/functions';
import CookiesMsg from './Cookies';
//import logo from '../img/logoCreative.png';
//import menuIcon from '../img/yellowBurger.png';


class MenuHdr extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',
        }
    }

    showMenu = () => {

        this.setState({
            menuDataClass: 'noHeight',
            showMenuClass: 'noOpacity',

        })

        setTimeout(
            function () {
                this.setState({
                    menuLinksClass: 'fullHeight',
                    hideMenuClass: 'fullOpacity',
                })
            }
                .bind(this), 50
        );
    }

    hideMenu = () => {
        this.setState({
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
        })

        setTimeout(
            function () {
                this.setState({
                    menuDataClass: 'fullHeight',
                    showMenuClass: 'fullOpacity',
                })
            }
                .bind(this), 50
        );
    }

    goToHomepage = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }


    showAbout = () => {
        document.location.href = `${process.env.PUBLIC_URL}/about/`
    }

    showNews = () => {
        document.location.href = `${process.env.PUBLIC_URL}/news/`
    }


    showContact = () => {
        document.location.href = `${process.env.PUBLIC_URL}/contact/`
    }


    showVideos = () => {
        document.location.href = `${process.env.PUBLIC_URL}/videos/`
    }

    showVojo = () => {
        document.location.href = `${process.env.PUBLIC_URL}/vojo/`
    }


    render() {
        return (
            <div>
                <CookiesMsg />
                <div className='menuHdr' >
                    <div className='logo' >
                        <img src={imgUrl() + 'logoCreative.png'} alt='Creative house group' onClick={this.goToHomepage} />
                    </div>
                    <div className='menuIcon' >
                        <img className={'showMenu ' + this.state.showMenuClass} src={imgUrl() + 'yellowBurger.png'} alt='Menu' onClick={this.showMenu} />
                        <div className={'hideMenu ' + this.state.hideMenuClass} onClick={this.hideMenu}>X</div>
                    </div>
                </div>

                <div className={'menuLinks ' + this.state.menuLinksClass} >
                    <div className='menuLinksFirst' onClick={this.showVideos} >Videos</div>
                    <div className='menuLinksChild' onClick={this.showAbout}>About</div>
                    <div className='menuLinksChild' onClick={this.showNews}>News</div>
                    <div className='menuLinksChild' onClick={this.showContact}>Contact</div>

                </div>
                <div className={'menuData ' + this.state.menuDataClass} >
                    {this.props.children}
                </div>

                <div className='footer'>
                    <div className='footerIcons'>
                        <table cellPadding='0' cellSpacing='0' width='100%' className='tblIcons'>
                            <tbody>
                                <tr valign='middle'>
                                    
                                    <td width='33%'>
                                        <a className='footerContact' href={`${process.env.PUBLIC_URL}/contact/`} >Contact</a>
                                    </td>
                                    <td width='33%' align='right' className='tdFooterIcons'>
                                        <a href='https://www.facebook.com/CreativeHouseGroup?ref=hl' rel="noreferrer" target='_blank'><img src={imgUrl() + 'icons/facebook.png'} alt='privacy' /></a>
                                        <a href='https://www.linkedin.com/company/creative-house-group-ltd' rel="noreferrer" target='_blank'><img src={imgUrl() + 'icons/linkedin.png'} alt='privacy' /></a>
                                        <a href='https://www.youtube.com/user/CreativeHouseLTD' rel="noreferrer" target='_blank'><img src={imgUrl() + 'icons/youtube.png'} alt='privacy' /></a>
                                        

                                    </td>


                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div className='copyright' >
                        <table cellPadding='0' cellSpacing='0'>
                            <tbody>
                                <tr>
                                    <td>
                                        &#169; 2021 Creative House Group. All rights reserved
                                    </td>
                                    <td className='tdPrivacy'>
                                        <a href='https://creative-house-group.com/privacy/' target='_blank' rel="noreferrer" >
                                            <table cellPadding='0' cellSpacing='0'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={imgUrl() + 'privacyIcon.png'} alt='privacy' />
                                                        </td>
                                                        <td>  Privacy policy
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div >
        )
    }
}

export default MenuHdr;

