import React from 'react';
import { imgUrl, dataObj, detectMob, getPlayImg } from '../general/functions'
import MenuHdr from './Dashboard'


let isScroll = false
let startX = 0
let campaignData = null

const loadCampaigns = async () => {

    await fetch(`${process.env.PUBLIC_URL}/campaign.json`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    )
        .then((res) => res.json())
        .then((res) => {
            console.log("all data")
            console.log(res)
            //console.log(res.campaigns[1])

            campaignData = res
            console.log(campaignData)


        }).catch(error => {
            campaignData = null
            console.log(error)
        });

    // campaignData = require('./campaign.json'); //(with path)

}


class News extends React.Component {
    constructor(props) {
        super(props);

        this.vidRef = React.createRef()
        this.vidBgRef = React.createRef()

        this.state = {
            menuDataClass: 'fullHeight',
            menuLinksClass: 'noHeight',
            hideMenuClass: 'noOpacity',
            showMenuClass: 'fullOpacity',
            showShadow: 'none',

            redirect: '',


            vidWrapper: 'noHeight',
            machinesMenuWrapper: 'noHeight noMarginTop ',
            showNavBottom: '',


            navLeftText: ' Contact ',
            navCenterText: ' | ',
            navRightText: ' Home ',
            centerNavClass: '',



            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
            currentVid: 'main',
            showNav: 'fullHeight',

            menuHomepage: 'fullHeight menuMarginBottom ',
            pauseClass: '',

            pauseTitle: 'pauseIcon.png',
            vidUrl: "",
            loopUrl: "",
            title: '',
            text: '',
            poster: '',
            campaignClass: '',
            nextid: '',
            previd: ''

        }
    }

    componentDidMount() {


        loadCampaigns()
        const prm = this.props.match.params.id

        if (campaignData !== null) {
            this.setCampaignData(this.props.match.params.id)
        }
        else {
            this.validateCampaignData()
        }


        if (detectMob()) {
            const vid = document.getElementById('vid_main')
            vid.pause()
            vid.currentTime = 3.75

            this.setState({
                pauseClass: 'noDisplay'
            })
        }
    }

    findCampaign = () => {


    }

    validateCampaignData = () => {
        if (campaignData === null) {
            setTimeout(
                function () {
                    this.validateCampaignData()
                }
                    .bind(this), 100
            );
        }
        else {

            this.setCampaignData(this.props.match.params.id)

        }

    }

    setCampaignData = (_campaignId) => {

        let found = false
        let i = 0
        let currentCampaign = null
        // alert(campaignData.campaigns.length)
        while (!found && (i < campaignData.campaigns.length)) {
            if (campaignData.campaigns[i].id === _campaignId) {
                found = true
                currentCampaign = campaignData.campaigns[i]
            }
            else {
                i++
            }
        }

        if (!found) {
            currentCampaign = campaignData.campaigns[0]/*campaignData.campaigns[campaignData.campaigns.length - 1]*/
        }

        const text = currentCampaign.text



        this.setState({
            campaignClass: currentCampaign.class,
            title: currentCampaign.title,
            text: text,
            vidUrl: currentCampaign.vidUrl,
            loopUrl: currentCampaign.loopUrl,
            poster: currentCampaign.poster,
            nextid: currentCampaign.nextid,
            previd: currentCampaign.previd
        },
            () => {
                document.title = currentCampaign.title + ' | Creative house group'
            })

        this.vidRef.current.src = currentCampaign.vidUrl
        this.vidBgRef.current.src = currentCampaign.loopUrl

    }

    getBr = (_txt) => {

    }

    playVid = () => {

        this.vidRef.current.play();
        this.setState({
            showShadow: 'inline',
            menuMaxWidth: '0vw',
            menuPaddingRight: '2vw',
        })

        //   if (!this.isPauseOrMobile()) {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        vid.pause()




    }


    hideVid = () => {
        this.vidRef.current.pause();
        this.vidRef.current.currentTime = 0;
        this.setState({
            showShadow: 'none',
            menuMaxWidth: '100vw',
            menuPaddingRight: '0',
        })


        // if (!this.isPauseOrMobile()) {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        vid.play()



        //}

    }




    onWheelVideo = (event) => {
        if (!isScroll) {
            isScroll = true
            if (event.deltaY < 0) {

                this.doScroll(true)
            }
            else if (event.deltaY > 0) {
                this.doScroll(false)
            }
        }
    }

    touchStart = (e) => {
        startX = e.touches[0].clientX
    }

    touchEnd = (e) => {
        const endX = e.changedTouches[0].clientX
        const diff = Math.abs(endX - startX)
        if (diff > 35) {
            if (endX > startX) {
                this.doScroll(true)
            }
            else {
                this.doScroll(false)
            }
        }
    }

    doScroll = (_scrollLeft) => {
        if (_scrollLeft) {
            this.showContact()
        }
        else {
            this.showHome()
        }
    }

    pauseCurrentVid = () => {
        const vid = document.getElementById("vid_" + this.state.currentVid)
        if (vid.paused) {
            vid.play()

            this.setState({
                pauseTitle: 'pauseIcon.png',
            })
        }
        else {
            vid.pause()

            this.setState({
                pauseTitle: getPlayImg(),

            })
        }

    }


    showHome = () => {
        document.location.href = `${process.env.PUBLIC_URL}/`
    }

    showContact = () => {
        document.location.href = `${process.env.PUBLIC_URL}/contact/`
    }

    showAbout = () => {
        document.location.href = `${process.env.PUBLIC_URL}/about/`
    }

    showJoin = () => {
        document.location.href = `${process.env.PUBLIC_URL}/join/`
    }

    render() {
        return (
            <div className='background' >
                <div className='vidContainer' id='vidContainer' onWheel={this.onWheelVideo} onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
                    <div className='vidContainerOn' id='wrapper_main' style={{

                    }}
                        onClick={this.playVid}
                    >
                        <div className='relative' >
                            <video playsInline autoPlay muted loop id='vid_main' preload='auto' ref={this.vidBgRef} >
                                <source src={this.state.loopUrl} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className='menuWrapper' style={{

                }} >
                    <MenuHdr >
                        <div >
                            <div className='aboutBg' onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} >
                                <div className={'aboutTitle ' + this.state.menuHomepage} >


                                    <div className={'aboutText newsText ' + this.state.campaignClass} >
                                        <span>{this.state.title}</span><br />

                                        {
                                            this.state.text.split('\n').map((item, key) => {
                                                return <div key={key}>{item}<br /></div>
                                            })
                                        }


                                        <br />

                                        <img className='newsPlay' src={imgUrl() + 'icons/playIcon-v3.png'} alt='Pause background'
                                            onClick={this.playVid}
                                        />


                                    </div>

                                </div>


                                <div className={'navWrapper ' + this.state.showNav}>
                                    <div className={'centerNav newsNav' + this.state.centerNavClass} >
                                        <div onClick={() => this.showContact()}>{this.state.navLeftText}</div>
                                        <div onClick={() => this.setCampaignData(this.state.previd)}><img src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                        <div>News</div>
                                        <div onClick={() => this.setCampaignData(this.state.nextid)}><img className='rotate180' src={imgUrl() + 'icons/arrow_icon.png'} alt='Nav' /></div>
                                        <div onClick={() => this.showHome()}>{this.state.navRightText}</div>
                                    </div>
                                    

                                </div>



                            </div>
                        </div>




                    </MenuHdr>

                </div>
                {this.state.redirect}

                <div className='shadow' style={{ display: this.state.showShadow, }} >
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                        <img src={imgUrl() + 'hide.png'} alt='Hide Video' style={{}} onClick={() => this.hideVid()} />

                        <video ref={this.vidRef} controls id='vidFull'>
                            <source src='' type="video/mp4" >
                            </source>
                        </video>
                    </div>
                </div>
                <div className={'pauseVid ' + this.state.pauseClass} onClick={this.pauseCurrentVid}>
                    <div>
                        <img src={imgUrl() + 'icons/' + this.state.pauseTitle} alt='Pause background' />
                    </div>
                </div>

            </div >
        )
    }
}
export default News;
