import React from 'react';

export default class Campaign extends React.Component {

    componentDidMount() {
        const prm = this.props.match.params.id
        alert(prm)
    }

    render() {
        return (
            <div>
                hi me
            </div>
        )
    }

}

